.slider {
    position: relative;
    width: 100%;
    height: 450px;
    overflow: hidden;
    background-size: cover;
}

.slider-container {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 100%;
    height: 100%;
}

.slide {
    min-width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
    color: #333;
    font-size: 2rem;
    font-family: Arial, sans-serif;
}
.slide-content {
    text-align: center;
    color: #000;
   background-Color: 'rgba(255, 255, 255, 0.)';
    padding: 20px;
    border-radius: 8px;
    max-width: 900px;
    font-weight: 700;
    /* You can adjust the max width as per your design */
}

h1 {
    color: #000;
    font-size: 2.5rem;
    /* Adjust the font size for the title */
    margin-bottom: 15px;
    
}

p {
    font-size: 0.9rem;
    /* Adjust the font size for the text */
}
.controls {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
}

.controls button {
    width: 10px;
    height: 10px;
    background-color: #999;
    border: none;
    border-radius: 50%;
    cursor: pointer;
}

.controls button.active {
    background-color: #333;
}