/* header.css */

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #f8f9fa;
    position: relative;
}

.header-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.logo h2 {
    margin: 0;
}

.hide-logo {
    display: none; /* Hide the logo when the menu is open */
}
.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}
.menu {
    display: flex; /* Show the menu on desktop by default */
    width: auto; /* Adjust width to fit content */
    flex-direction: row; /* Horizontal menu layout on desktop */
    background-color: transparent; /* Transparent background for desktop */
    position: relative; /* Relative position for desktop */
    padding: 0; /* Remove padding */
    top:10px;
}

.menu.open {
    display: flex; /* Show menu when open */
}

.menu ul {
    display: flex;
    flex-direction: row; /* Horizontal menu items on desktop */
    gap: 20px; /* Adjust gap as needed */
    list-style-type: none;
    margin: 0;
    padding: 0;
    
}

.menu ul li a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
}

/* Styles for the search bar */
.search-container {
    position: relative;
    display: flex;
    align-items: center;
}

.search-bar {
    padding: 8px 40px 8px 12px; /* Add padding to accommodate the icon */
    border: 1px solid #ccc;
    border-radius: 4px;
}

.search-icon {
    position: absolute;
    right: 10px;
    font-size: 18px;
    color: #aaa;
}

.mobile-search {
    display: none; /* Hidden on desktop */
    margin-top: 10px;
}

.navbar-toggler {
    display: none;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.right-section {
    display: flex;
    align-items: center;
    gap: 20px;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
    .header {
        flex-direction: row;
        align-items: center;
    }

    .right-section {
        display: none; /* Hide search and other elements on mobile */
    }

    .menu {
        display: none; /* Hide menu by default on mobile */
        width: 100%;
        flex-direction: column; /* Vertical menu on mobile */
        background-color: white; /* Full white background */
        position: fixed; /* Fixed position on mobile */
        top: 0;
        left: 0;
        z-index: 1000; /* Ensure it overlays other content */
        padding: 20px;
    }

    .menu.open {
        display: flex; /* Show menu when open */
    }

    .menu ul {
        flex-direction: column; /* Vertical menu items on mobile */
        gap: 20px;
        width: 100%;
        text-align: left;
    }

    .mobile-search {
        display: block; /* Show search bar on mobile */
    }

    .navbar-toggler {
        display: block;
        position: absolute;
        right: 20px;
        top: 10px;
    }
}

@media screen and (max-width: 480px) {
    .header {
        padding: 10px;
    }

    .logo h2 {
        font-size: 1.5rem;
    }

    .menu ul {
        gap: 15px;
    }

    .search-bar {
        padding: 10px;
    }
}
