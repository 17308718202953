.testimonial-slider {
    position: relative;
    overflow: hidden;
    background-color: #18455d !important;
}
.bg-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}
.testimonial-slide {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.testimonial-content {
    min-width: 100%;
    box-sizing: border-box;
    text-align: center;
}

.rounded-circle {
    border-radius: 50%;
    width: 80px;
    height: 80px;
}

.quote-icon {
    font-size: 2em;
    color: #007bff;
}

.prev-slide,
.next-slide {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
}

.prev-slide {
    left: 10px;
}

.next-slide {
    right: 10px;
}
.bg-secondary {
    --bs-bg-opacity: 1;
    background-color: #18455d !important;
}
.bg-primary {
    --bs-bg-opacity: 1;
    background-color: #e74860 !important;
}