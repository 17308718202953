.hero-section {
    position: relative;
    width: 100%;
    height: 500px;
    /* Adjust height as needed */
   
    /* Replace with your image path */
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* Black overlay with 50% opacity */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
}

.content {
    z-index: 1;
    /* Ensures content is above the overlay */
    padding-left: 150px;
    padding-right: 150px;
    color: #fff;
}

.contact-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #fff;
    color: #000;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
}

.contact-button:hover {
    background-color: #f0f0f0;
}

@media (max-width: 600px) {
    .content {
        z-index: 1;
        /* Ensures content is above the overlay */
        padding-left: 30px;
        padding-right: 30px;
        color: #fff;
    }
}