/* Media query for mobile */
@media only screen and (max-width: 768px) {
  .card {
    flex: 0 0 100%; /* Shows 1 item per view for smaller screens */
  }

  .prev-button, .next-button {
    font-size: 1.5rem; /* Make the buttons smaller for mobile */
  }

  h2 {
    font-size: 28px; /* Adjust heading size for mobile */
  }
}

@media only screen and (max-width: 480px) {
  .card {
    flex: 0 0 100%; /* Ensure one item per view on smaller mobile screens */
  }

  h2 {
    font-size: 24px; /* Further reduce heading size on small screens */
  }

  .prev-button, .next-button {
    font-size: 1rem; /* Smaller navigation buttons */
  }
}

.services-section {
    position: relative;
    overflow: hidden;
    padding: 50px;
    height: 500px;
    background-color: #0c3246 !important;
}
