/* Ensure this CSS is included in the proper file and loaded correctly */
.icon {
    color: #000;
    /* Default icon color */
    font-size: 36px;
    /* Adjust size as needed */
    transition: color 0.3s ease;
}

/* Make sure this is correctly scoped to where you want it to apply */
.contact-info-block:hover .icon {
    color: #fff;
    /* Icon color on hover */
}