.services-section {
    position: relative;
    overflow: hidden;
    padding: 50px;
    
    
}

.services-container {
    position: relative;
    overflow: hidden;
}

.services-wrapper {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.card {
    flex: 0 0 33.33%;
    /* Show 3 items at a time */
    box-sizing: border-box;
    padding: 10px;
    min-height: 300px;
    /* Adjust this value as needed */
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;
    /* Optional: Add background color */
}

.card .card-front {
    background-size: cover;
    background-position: center;
    height: 280px;
    /* Adjust as needed */
    display: flex;
    align-items: center;
    justify-content: center;
}

.card .card-back {
    padding: 20px;
}

.prev-button,
.next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #333;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
}

.prev-button {
    left: 10px;
}

.next-button {
    right: 10px;
}

.jarallax {
    height: 70vh;
    /* Or any height you prefer */
}


@media (max-width: 767px) {
    .row {
      flex-direction: column-reverse; /* Reverses the order of elements on mobile */
    }
    
    .mobile-first-menu {
      margin-bottom: 20px; /* Adds space between menu and content */
    }
  
    .wow {
      margin-top: 0; /* Remove any extra margin for mobile */
    }
  
    .card {
      flex: 0 0 100%; /* Display one card at a time */
    }
  
    .prev-button, .next-button {
      display: none; /* Hide navigation buttons on mobile */
    }
  
    .services-section {
      padding: 20px; /* Reduce padding for smaller screens */
    }
  }